import { Meta } from "@/components/Pages/Meta";
import Image from "next/image";

import logoPic from "@/public/logo.png";
import { Flex, Heading, Text, VStack } from "@chakra-ui/react";

import { Roboto_Slab } from "@next/font/google";

const roboto_slab = Roboto_Slab({ weight: ["500"] });

export default function Home() {
  return (
    <>
      <Meta
        title={"Instituto Plinio Corrêa de Oliveira"}
        description={"Instituto Plinio Corrêa de Oliveira"}
      />
      <Flex
        flexDir={"column"}
        alignItems="center"
        justifyContent={"space-evenly"}
        w="fit-content"
        h={"100vh"}
        mx={"auto"}
      >
        <VStack spacing={8}>
          <Image
            src={logoPic}
            width={300}
            height={300}
            alt="Logo do Instituto Plinio Corrêa de Oliveira"
            placeholder="blur"
            priority
          />
          <Heading
            as="h1"
            fontSize={"3xl"}
            style={roboto_slab.style}
            textTransform="uppercase"
            textAlign={"center"}
            sx={{
              "@media screen and (max-width: 780px)": {
                fontSize: "lg",
              },
            }}
          >
            <Text
              as="span"
              fontSize={"5xl"}
              sx={{
                "@media screen and (max-width: 780px)": {
                  fontSize: "3xl",
                },
              }}
            >
              I
            </Text>
            nstituto
            <br />
            <Text
              as="span"
              fontSize={"5xl"}
              sx={{
                "@media screen and (max-width: 780px)": {
                  fontSize: "3xl",
                },
              }}
            >
              P
            </Text>
            linio{" "}
            <Text
              as="span"
              fontSize={"5xl"}
              sx={{
                "@media screen and (max-width: 780px)": {
                  fontSize: "3xl",
                },
              }}
            >
              C
            </Text>
            orrêa de{" "}
            <Text
              as="span"
              fontSize={"5xl"}
              sx={{
                "@media screen and (max-width: 780px)": {
                  fontSize: "3xl",
                },
              }}
            >
              O
            </Text>
            liveira
          </Heading>
        </VStack>
      </Flex>
    </>
  );
}
